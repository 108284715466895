import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import { Avatar, List, ListItem, Grid, Checkbox } from "@material-ui/core";
import { useGroupContext } from "../../../providers/GroupProvider";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import SearchBar from "../../SearchBar";
import * as _ from "lodash";
import clsx from "clsx";
import StyledPanel from "../StyledPanel";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const GroupsPanel = ({
  selected,
  onChange,
  className,
  expandable,
  groupType,
  disabled,
}) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { functions } = useGroupContext();
  const { getGroups } = functions;
  const [groups, setGroups] = useState({ total: 0, collection: [] });
  const [selectedGroups, setSelectedGroups] = useState(selected);
  const [searchTerm, setSearchTerm] = useState();
  const selectedRef = useRef(selected);

  useEffect(() => {
    getGroups(groupType, scopeAwareFacilityID, 50, 0, searchTerm)
      .then((res) => {
        if (res.status === 200) {
          setGroups({
            collection: res.data.collection,
            total: res.data.totalCount,
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to get groups", {
          variant: "error", tag: "FailedToGetGroups"
        });
      });
  }, [getGroups, searchTerm, scopeAwareFacilityID, groupType]);

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
      setSelectedGroups(selected);
    }
  }, [selected]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const handleGroupChecked = (e) => {
    const groupID = e.target.value;
    let tmpGroups = [...selectedGroups];
    if (tmpGroups.includes(groupID)) {
      // remove
      tmpGroups.splice(tmpGroups.indexOf(groupID), 1);
    } else {
      // add
      tmpGroups.push(groupID);
    }

    onChange(tmpGroups);
    setSelectedGroups(tmpGroups);
  };

  const isChecked = (groupID) => {
    return selectedGroups.includes(groupID);
  };

  const renderContent = () => {
    return (
      <Grid container direction="column">
        <Grid item>
          <SearchBar
            className={clsx("group-search-bar", classes.searchBar)}
            delay={100}
            placeholder={`Search ${groups.total} groups`}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item className={clsx("groups")}>
          <List className={clsx("group-list", classes.groupList)}>
            {groups.collection.map((group, index) => {
              let checked = isChecked(group.groupID);
              return (
                <ListItem
                  key={index}
                  data-value={`${group.name}`}
                  data-checked={checked}
                  className={clsx('group-row')}
                >
                  <Checkbox
                    className={clsx(`group-checkbox-${group.name}`)}
                    color="primary"
                    onChange={handleGroupChecked}
                    value={group.groupID}
                    checked={checked}
                    disabled={disabled}
                  />
                  <Avatar
                    className={clsx(
                      `group-avatar-${group.name}`,
                      classes.groupAvatar
                    )}
                    aria-label="group"
                    title={group.groupID}
                  >
                    {group.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <div className={clsx(classes.groupInfo)}>
                    <Typography
                      className={clsx(
                        `group-name-${group.name}`,
                        classes.groupName
                      )}
                    >
                      {group.name}
                    </Typography>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    );
  };

  if (expandable === false) {
    return (
      <StyledPanel
        className={clsx(className)}
        cardContent={renderContent()}
        headerContent={
          <Typography className={clsx(classes.title)} variant="h5">
            Groups
          </Typography>
        }
      />
    );
  } else
    return (
      <FormControl
        variant="outlined"
        className={clsx("groups-panel", classes.groupFormControl, className)}
      >
        <Accordion>
          <AccordionSummary
            className={clsx(classes.panelSummary, "groups-panel-expand-more")}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Groups</Typography>
          </AccordionSummary>
          <AccordionDetails className={clsx(classes.expansionPanel)}>
            {renderContent()}
          </AccordionDetails>
        </Accordion>
      </FormControl>
    );
};

GroupsPanel.defaultProps = {
  selected: [],
  onChange: () => {},
  expandable: true,
  disabled: false,
};

GroupsPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  expandable: PropTypes.bool,
  groupTypes: PropTypes.oneOf(["user", "accessHolder"]),
  disabled: PropTypes.bool,
};

export default GroupsPanel;
