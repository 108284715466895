import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleRow: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  credentialList: {
    width: "100%",
    maxHeight: "400px",
    overflowY: "auto",
  },
  btnContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(1),
      maxHeight: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  rowContainer: {
    display: "inline-flex",
    flex: "1",
    justifyContent: "flex-start",
  },
  rowContent: {
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
  },
  noPad: {
    padding: "0 !important",
    outline: "none !important",
  },
}));
