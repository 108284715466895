import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { useStyles } from "./style";
import clsx from "clsx";

const StyledPanel = ({
  ["data-id"]: dataID,
  ["data-testid"]: testID,
  headerContent,
  cardContent,
  className
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      data-testid={testID}
      data-id={dataID}
      variant="outlined"
    >
      <div id="styled-panel-header" className={classes.cardHeader}>
        {headerContent}
      </div>
      <CardContent className={classes.cardContent}>{cardContent}</CardContent>
    </Card>
  );
};

StyledPanel.propTypes = {
  headerContent: PropTypes.node,
  cardContent: PropTypes.node,
  className: PropTypes.string
};

export default StyledPanel;
