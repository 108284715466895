import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";
import StyledPanel from "../../StyledPanel";
import { useStyles } from "./style";
import DeleteIcon from "@material-ui/icons/Delete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { isUndefined, isEmpty, isNull } from "lodash";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressSubformSchema } from "../../../Forms/ContactSubform/AddressSubform";
import { EmailSubformSchema } from "../../../Forms/ContactSubform/EmailSubform";
import { PhoneSubformSchema } from "../../../Forms/ContactSubform/PhoneSubform";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import ContactSubform from "../../../Forms/ContactSubform";
import { generateUUID } from "../../../Rate";

const GroupContractInfo = ({
  className,
  groupContract,
  onDelete,
  onChange,
  accessHolderCount,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const groupContractDelete = hasPermissions(["groupcontracts.delete"]);
  const groupContractEdit = hasPermissions(["groupcontracts.edit"]);
  const groupContractAdd = hasPermissions(["groupcontracts.add"]);

  const GroupContractSchema = Yup.object().shape({
    accountNumber: Yup.string().test(
      "is-valid-account-number",
      "* Required",
      values => values === "" || values.trim() !== ""
    ).required("* Required"),
    name: Yup.string().required("* Required"),
    contactInfo: Yup.object().shape({
      firstName: Yup.string().required("* Required"),
      lastName: Yup.string().optional(),
      addresses: Yup.array().of(AddressSubformSchema),
      emails: Yup.array().of(EmailSubformSchema),
      phoneNumbers: Yup.array().of(PhoneSubformSchema),
    }),
  });

  const defaultValues = {
    accountNumber: "",
    name: "",
    contactInfo: {
      contactID: "00000000-0000-0000-0000-000000000000",
      firstName: "",
      lastName: "",
      addresses: [
        {
          type: "Home",
          addressLine1: "",
          addressLine2: "",
          countryID: "",
          city: "",
          subdivisionID: "",
          postalCode: "",
          contactID: "00000000-0000-0000-0000-000000000000",
        },
      ],
      emails: [{ type: "Personal", emailAddress: "" }],
      phoneNumbers: [{ type: "Mobile", number: "" }],
    },
  };

  const methods = useForm({
    resolver: yupResolver(GroupContractSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    formState: { isDirty, isSubmitting, errors, isValid },
  } = methods;

  const setFormValuesFromContract = (data) => {
    const formValues = {
      accountNumber: data?.accountNumber ?? "",
      name: data?.name ?? "",
      contactInfo: {
        addresses:
          data?.contactInfo?.addresses?.length > 0
            ? data?.contactInfo?.addresses
            : defaultValues.contactInfo.addresses,
        contactID: data?.contactInfo?.contactID ?? "",
        entityID: data?.contactInfo?.entityID,
        emails:
          data?.contactInfo?.emails?.length > 0
            ? data?.contactInfo?.emails
            : defaultValues.contactInfo.emails,
        firstName: data?.contactInfo?.firstName,
        lastName: data?.contactInfo?.lastName,
        phoneNumbers:
          data?.contactInfo?.phoneNumbers?.length > 0
            ? data?.contactInfo?.phoneNumbers
            : defaultValues.contactInfo.phoneNumbers,
      },
    };
    reset(formValues);
  };

  const createOrUpdateGroupContract = (values) => {
    onChange(values);
  };

  const handleDeleteGroupContract = async () => {
    const confirmed = await textConfirmation({
      title: "Group Contract Delete",
      message: `To confirm the deletion of this group contract, please type the account name in the input box below.`,
      textConfirmation: groupContract.name,
    });
    if (confirmed) {
      onDelete(groupContract.contractID);
    }
  };

  const shouldModificationBeDisabled = () => {
    if (
      isUndefined(groupContract?.contractID) ||
      isEmpty(groupContract?.contractID)
    ) {
      return !groupContractAdd;
    } else {
      return !groupContractEdit;
    }
  };

  useEffect(() => {
    if (
      groupContract !== undefined &&
      groupContract?.contractID !== undefined
    ) {
      setFormValuesFromContract(groupContract);
    } else {
      const accNumber = generateUUID();
      reset({ ...defaultValues, accountNumber: accNumber });
    }
  }, [groupContract]);

  const formatTextField = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    setValue(e.target.name, val.trim());
    trigger(e.target.name);
  };

  return (
    <StyledPanel
      className={clsx("group-contract-info-panel", className)}
      headerContent={
        <div className={clsx("header", classes.header)}>
          <div className={clsx("header-title")}>
            <Typography className={clsx("header-left", classes.headerLeft)}>
              {small ? "Group Contract" : "Group Contract Info"}
            </Typography>
          </div>
          <div className={clsx("button-header", classes.btnGroup)}>
            {groupContractDelete &&
              !isUndefined(groupContract?.contractID) &&
              (isUndefined(accessHolderCount) || accessHolderCount < 1) && (
                <Button
                  size="small"
                  className={clsx("delete-groupcontract-btn")}
                  startIcon={
                    small === true && (
                      <DeleteIcon className={clsx("delete-icon")} />
                    )
                  }
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteGroupContract}
                >
                  {!small && "Delete Group Contract"}
                </Button>
              )}
          </div>
        </div>
      }
      cardContent={
        <div className={clsx("card-content", classes.contentRoot)}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(
                async () => {
                  const data = getValues();

                  await createOrUpdateGroupContract(data)
                }
              )}
              noValidate
            >
              <div className={clsx("card-top", classes.top)}>
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(
                        "accountNumber-field",
                        classes.accountNumberInput
                      )}
                      size="small"
                      label="Account #"
                      variant="outlined"
                      required
                      error={!!errors.accountNumber}
                      helperText={
                        errors.accountNumber && errors.accountNumber.message
                      }
                      InputProps={{
                        readOnly: Boolean(shouldModificationBeDisabled()),
                        "aria-readonly": Boolean(
                          shouldModificationBeDisabled()
                        ),
                        disabled: Boolean(shouldModificationBeDisabled()),
                      }}
                      inputProps={{ "data-testid": "account-number-field" }}
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(
                        "account-name-field",
                        classes.accountNumberInput
                      )}
                      size="small"
                      label="Account Name"
                      variant="outlined"
                      data-id="account-name-field"
                      onBlur={formatTextField}
                      error={!!errors.name}
                      required={true}
                      helperText={errors.name && errors.name.message}
                      InputProps={{
                        readOnly: Boolean(shouldModificationBeDisabled()),
                        "aria-readonly": Boolean(
                          shouldModificationBeDisabled()
                        ),
                        inputProps: {
                          "data-testid": "groupcontract-name",
                          required: true,
                        },
                        disabled: Boolean(shouldModificationBeDisabled()),
                      }}
                    />
                  )}
                />
              </div>
              <div
                className={clsx(
                  "contact-form-container",
                  classes.root,
                  classes.contactForm
                )}
              >
                <ContactSubform
                  disabled={shouldModificationBeDisabled()}
                  requiredFields={["firstName"]}
                />
                {!shouldModificationBeDisabled() && (
                  <Button
                    className={clsx(
                      "groupcontract-submit-btn",
                      classes.submitBtn
                    )}
                    data-testid="groupcontract-submit-btn-test"
                    inputProps={{
                      "data-testid": "groupcontract-submit-btn-test",
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={
                      !isDirty || !isEmpty(errors) || isSubmitting || !isValid
                    }
                  >
                    Submit
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      }
    />
  );
};

GroupContractInfo.defaultProps = {
  onDelete: () => {},
  onChange: () => {},
};

GroupContractInfo.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

export default GroupContractInfo;
