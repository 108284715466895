export const valueToDayTransformer = (days, short = false) => {
  if (!days) return;

  const tmp = [];
  days.forEach((day) => {
    switch (day) {
      case 1:
        tmp.push(short ? abbreviateDays("Monday") : "Monday");
        break;
      case 2:
        tmp.push(short ? abbreviateDays("Tuesday") : "Tuesday");
        break;
      case 3:
        tmp.push(short ? abbreviateDays("Wednesday") : "Wednesday");
        break;
      case 4:
        tmp.push(short ? abbreviateDays("Thursday") : "Thursday");
        break;
      case 5:
        tmp.push(short ? abbreviateDays("Friday") : "Friday");
        break;
      case 6:
        tmp.push(short ? abbreviateDays("Saturday") : "Saturday");
        break;
      case 7:
        tmp.push(short ? abbreviateDays("Sunday") : "Sunday");
        break;
    }
  });

  return tmp;
};

export const abbreviateDays = (days) => {
  if (!days) return;

  const tmp = [];
  days.forEach((day) => {
    switch (day.toLowerCase()) {
      case "monday":
        tmp.push("M");
        break;
      case "tuesday":
        tmp.push("T");
        break;
      case "wednesday":
        tmp.push("W");
        break;
      case "thursday":
        tmp.push("Th");
        break;
      case "friday":
        tmp.push("F");
        break;
      case "saturday":
        tmp.push("S");
        break;
      case "Sunday":
        tmp.push("Su");
        break;
    }
  });
  return tmp;
};

export const dayToValueConverter = (days) => {
  if (!days) return;

  const tmp = [];
  days.forEach((day) => {
    switch (day.toLowerCase()) {
      case "monday":
        tmp.push(1);
        break;
      case "tuesday":
        tmp.push(2);
        break;
      case "wednesday":
        tmp.push(3);
        break;
      case "thursday":
        tmp.push(4);
        break;
      case "friday":
        tmp.push(5);
        break;
      case "saturday":
        tmp.push(6);
        break;
      case "sunday":
        tmp.push(7);
        break;
    }
  });
  return tmp;
};
