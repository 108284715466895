import withStyles from "@material-ui/core/styles/withStyles";
import { AccordionSummary } from "@material-ui/core";

const ExpansionPanelSummaryLeftIcon = withStyles({
  expandIcon: {
    order: -1,
  },
  expanded: {
    "&$expandIcon": {
      transform: "rotate(90deg)",
    },
  },
})(AccordionSummary);

export default ExpansionPanelSummaryLeftIcon;
