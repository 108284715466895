import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& #styled-panel-header": {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        height: "unset",
      },
      [theme.breakpoints.down("sm")]: {
        height: "unset",
      },
    },
  },
  headerContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: "1",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  searchBar: {
    maxWidth: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  accountUsersCheckbox: {},
  saveBtn: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  createUserBtn: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  headerBtnGroup: {},
  paginationAlign: {
    display: "flex",
    justifyContent: "end"
  }
}));
