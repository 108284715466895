import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: { color: theme.palette.white?.main, fontWeight: "bold" },
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    [theme.breakpoints.up("lg")]: {
      maxHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
      minHeight: "75px",
    },
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block"
    },
    [theme.breakpoints.down("md")]: {
      display: "block"
    },
  },
  headerBalance: {
    display: "inline-flex",
    justifyContent: "right",

    [theme.breakpoints.down("md")]: {
      display: "contents",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      display: "contents",
      width: "100%",
    },
  },
  cardButton: {
    marginTop: 5,
  }
}));
