import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";
import StyledPanel from "../../StyledPanel";
import { useStyles } from "./style";
import DeleteIcon from "@material-ui/icons/Delete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../../auth/apiClient";
import ValidationAccountService from "../../../../services/ValidationAccountService";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressSubformSchema } from "../../../Forms/ContactSubform/AddressSubform";
import { EmailSubformSchema } from "../../../Forms/ContactSubform/EmailSubform";
import { PhoneSubformSchema } from "../../../Forms/ContactSubform/PhoneSubform";
import ContactSubform from "../../../Forms/ContactSubform";
import { isUndefined, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../../constants";

const validationAccountService = new ValidationAccountService(apiClient);

const ValidationAccountPanel = ({
  className,
  onDelete,
  onChange,
  validationAccountData,
  contractHolderID
}) => {
  //#region Declarations
  const classes = useStyles();
  const userType = useSelector(state => state.user.UserType);
  const facilityID = useSelector(state => state.entities.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const enqueueSnackbar = useEnqueueSnackbar();
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const validationAccountsEdit = hasPermissions(["validationaccounts.edit"]);
  const validationAccountsDelete = hasPermissions([
    "validationaccounts.delete"
  ]);
  const validationAccountsAdd = hasPermissions(["validationaccounts.add"]);

  const [validationAccount, setValidationAccount] = useState({
    ...validationAccountData,
    contractHolderID: contractHolderID,
    entityID: scopeAwareFacilityID
  });

  const ValidationAccountSchema = Yup.object().shape({
    validationAccountName: Yup.string().required("* Required"),
    contactInfo: Yup.object().shape({
      firstName: Yup.string().required("* Required"),
      lastName: Yup.string(),
      addresses: Yup.array().of(AddressSubformSchema),
      emails: Yup.array().of(EmailSubformSchema),
      phoneNumbers: Yup.array().of(PhoneSubformSchema)
    })
  });

  const defaultValues = {
    validationAccountName: validationAccount.validationAccountName,
    contractHolderID: contractHolderID,
    contactInfo: {
      contactID: validationAccount.contactInfo?.contactID,
      firstName: validationAccount.contactInfo?.firstName,
      lastName: validationAccount.contactInfo?.lastName,
      addresses:
        validationAccount.contactInfo?.addresses?.length > 0
          ? validationAccount.contactInfo.addresses
          : [
              {
                type: "Home",
                addressLine1: "",
                addressLine2: "",
                countryID: "",
                city: "",
                subdivisionID: "",
                postalCode: ""
              }
            ],
      emails:
        validationAccount.contactInfo?.emails?.length > 0
          ? validationAccount.contactInfo.emails
          : [{ type: "Personal", emailAddress: "" }],
      phoneNumbers:
        validationAccount.contactInfo?.phoneNumbers?.length > 0
          ? validationAccount.contactInfo.phoneNumbers
          : [{ type: "Mobile", number: "" }]
    }
  };

  const methods = useForm({
    resolver: yupResolver(ValidationAccountSchema),
    defaultValues: defaultValues,
    mode: "onChange"
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { isDirty, isSubmitting, errors, isValid }
  } = methods;

  //#endregion

  //#region Functions
  /**
   * Creates/updates an account
   * @returns
   * @param values
   */
  async function createOrUpdateValidationAccount(values) {
    const { validationAccountName, contactInfo } = values;
    if (isUndefined(contactInfo)) return;
    if (isUndefined(validationAccountName)) return;
    validationAccount.validationAccountName = validationAccountName;
    validationAccount.userType = USER_TYPES.ValidationAccount;
    contactInfo.entityID = scopeAwareFacilityID;
    validationAccount.contactInfo = contactInfo;

    // User has been created successfully, create validation account with userId
    let response;
    if (isUndefined(validationAccount.contractHolderID)) {
      try {
        response = await validationAccountService.createValidationAccount(
          validationAccount
        );
      } catch (err) {
        enqueueSnackbar("Failed to create validation account", {
          variant: "error",
          tag: "FailedToCreateValidationAccount"
        });
      }
    } else {
      try {
        response = await validationAccountService.updateValidationAccount(
          validationAccount
        );
      } catch (err) {
        enqueueSnackbar("Failed to update validation account", {
          variant: "error",
          tag: "FailedToCreateValidationAccount"
        });
      }
    }
    if (response?.data) {
      enqueueSnackbar(
        `Successfully ${
          !isUndefined(validationAccount.contractHolderID) ||
          !isUndefined(validationAccount.userID)
            ? "updated"
            : "created"
        } Validation Account`,
        {
          variant: "success"
        }
      );

      setValidationAccount(prev => ({
        ...response.data
      }));
      onChange(response.data);
    }
  }

  /**
   * Deletes a validation account and associated user
   */
  const handleDeleteValidationAccount = async () => {
    const getTextConfirmation = true;
    let confirmed = true;
    if (getTextConfirmation) {
      confirmed = await textConfirmation({
        title: "Validation Account Delete",
        message: `To confirm the deletion of this validation account, please enter this validation account's name in the input box below.`,
        textConfirmation: getValues().validationAccountName
      });
    }

    if (confirmed) {
      try {
        await validationAccountService.deleteValidationAccountAndUsers(
          validationAccount.contractHolderID
        );
        reset();
      } catch {
        enqueueSnackbar("Failed to delete validation account", {
          variant: "error",
          tag: "FailedToDeleteValidationAccount"
        });
        return;
      }
      enqueueSnackbar("Validation Account deleted successfully", {
        variant: "success"
      });
      onDelete();
    }
  };

  //#endregion

  const shouldBeDisabled = () => {
    return (
      (contractHolderID && !validationAccountsEdit) ||
      (!contractHolderID && !validationAccountsAdd)
    );
  };

  const formatTextField = e => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    setValue(e.target.name, val.trim());
    trigger(e.target.name);
  };

  return (
    <>
      <StyledPanel
        className={className}
        headerContent={
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography>Validation Account Info</Typography>
            </div>
            <div className={clsx(["btn-header", classes.headerButtons])}>
              {!isUndefined(validationAccount.contractHolderID) &&
                !isNaN(validationAccount.contractHolderID) &&
                validationAccountsDelete && (
                  <Button
                    startIcon={small === true && <DeleteIcon />}
                    className={clsx(
                      "btn-delete-account",
                      classes.mobileBtn,
                      classes.deleteBtn
                    )}
                    variant="contained"
                    color="secondary"
                    onClick={handleDeleteValidationAccount}
                  >
                    {!small && "Delete Validation Account"}
                  </Button>
                )}
            </div>
          </div>
        }
        cardContent={
          <div className={classes.contentRoot}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(
                  async data => await createOrUpdateValidationAccount(data)
                )}
                noValidate
              >
                <div className={classes.top}>
                  <Controller
                    name="validationAccountName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={clsx([
                          "val-name-input",
                          classes.validationNameInput
                        ])}
                        label="Validation Account Name"
                        variant="outlined"
                        id="validationAccountName"
                        required
                        onBlur={formatTextField}
                        error={!!errors.validationAccountName}
                        helperText={
                          errors.validationAccountName &&
                          errors.validationAccountName.message
                        }
                        InputProps={{
                          readOnly: Boolean(
                            shouldBeDisabled() ||
                              userType == USER_TYPES.ValidationAccount
                          ),
                          "aria-readonly": Boolean(
                            shouldBeDisabled() ||
                              userType == USER_TYPES.ValidationAccount
                          ),
                          disabled: Boolean(
                            shouldBeDisabled() ||
                              userType == USER_TYPES.ValidationAccount
                          )
                        }}
                      />
                    )}
                  />

                  {!shouldBeDisabled() &&
                    (validationAccountsAdd || validationAccountsEdit) && (
                      <Button
                        className={clsx(["btn-submit", classes.submitBtn])}
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={
                          !isDirty ||
                          !isEmpty(errors) ||
                          !isValid ||
                          isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    )}

                  {!isUndefined(contractHolderID) &&
                    !isNaN(contractHolderID) && (
                      <Controller
                        name="contractHolderID"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={clsx([
                              "val-name-input",
                              classes.validationNameInput
                            ])}
                            label="Validation Account ID"
                            variant="outlined"
                            disabled={true}
                          />
                        )}
                      />
                    )}
                </div>
                <div
                  className={clsx(
                    "contact-form-container",
                    classes.root,
                    classes.contactForm
                  )}
                >
                  <ContactSubform 
                    disabled={shouldBeDisabled()}
                    requiredFields={["firstName"]}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        }
      />
    </>
  );
};

ValidationAccountPanel.defaultProps = {
  onDelete: () => {},
  onChange: () => {}
};

ValidationAccountPanel.propTypes = {
  className: PropTypes.string,
  validationAccountName: PropTypes.string,
  contractHolderID: PropTypes.number,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  validationAccountData: PropTypes.object
};

export default ValidationAccountPanel;
