import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: "1",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  searchBar: {
    maxWidth: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  headerBtnGroup: {},
  createUserBtn: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  saveBtn: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));
