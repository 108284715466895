import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  userFormControl: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  searchBar: {},
  expansionPanel: {
    display: "flex",
  },
  panelSummary: {
    height: "55px",
  },
}));
