import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleRow: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  validationOffersList: {
    width: "100%",
    maxHeight: "400px",
    overflowY: "auto",
  },
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    [theme.breakpoints.up("lg")]: {
      maxHeight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      maxHeight: "unset",
    },
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  btnContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(1),
      maxHeight: "2.5em",
    },
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  rowContainer: {
    display: "inline-flex",
    flex: "1",
    justifyContent: "flex-start",
    maxHeight: "1.5em",
    paddingBottom: theme.spacing(5),
  },
  rowContent: {
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
  },
  noPad: {
    padding: "0 !important",
    outline: "none !important",
  },
  validationOffersTitle: {
    color: theme.palette.white?.main,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    width: "fit-content",
  },
  panelContent: {
    display: "inline-block",
    padding: theme.spacing(3),
    width: "100%"
  }
}));