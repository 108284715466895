import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import SearchBar from "../../SearchBar";
import UserList from "../../List/Users";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const UserPanel = ({ selected, onChange, disabled }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [searchTerm, setSearchTerm] = useState();

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  return (
    <FormControl
      className={clsx("user-panel", classes.userFormControl)}
      variant="outlined"
    >
      <Accordion>
        <AccordionSummary
          className={clsx(classes.panelSummary, "user-panel-expand-more")}
          expandIcon={<ExpandMoreIcon className={clsx("expand-more-icon")} />}
        >
          <Typography className={clsx("users")}>Users</Typography>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.expansionPanel)}>
          <Grid container direction="column">
            <Grid item>
              <SearchBar
                className={clsx("user-panel-searchbar", classes.searchBar)}
                delay={100}
                placeholder={`Search users by Name or email address`}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item className={clsx('users')}>
              <UserList                
                disabled={disabled}
                selected={selected}
                onChange={onChange}
                facilityID={scopeAwareFacilityID}
                limit={50}
                searchTerm={searchTerm}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

UserPanel.defaultProps = {
  selected: [],
  onChange: () => {},
  disabled: false,
};

UserPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default UserPanel;
