import React, { useState } from "react";
import PropTypes from "prop-types";

/* Components */
import SearchBar from "../../SearchBar";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

/* Style */
import { useStyles } from "./headerStyle";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";

/* State */
import useCurrentUser from "../../../hooks/useCurrentUser";

/* Constants */
import { USER_TYPES } from "../../../constants";

/* Utilities */
import * as _ from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";
import ValidationUserFormModal from "../../Modals/ValidationUserFormModal";
import AccountUsersCheckbox from "./AccountUsersCheckbox";

const isIdValid = (id) => {
  return !_.isUndefined(id) && !_.isNaN(id);
};

export default function Header({
  validationAccountID,
  dirty,
  onSearchChange,
  accountUsersClicked,
  onUserCreated,
  onSave,
}) {
  const classes = useStyles();
  const user = useCurrentUser();
  const { hasPermissions } = useHasPermissions();
  const ViewValidationAccountUsers = hasPermissions([
    "validationaccountusers.view",
  ]);
  const CreateValidationAccountUser = hasPermissions([
    "validationaccountusers.add",
  ]);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getContentByUserType = () => {
    return (
      <>
        {user.UserType != USER_TYPES.ValidationAccount &&
          ViewValidationAccountUsers && (
            <AccountUsersCheckbox onClick={accountUsersClicked} />
          )}
        <div className={classes.headerBtnGroup}>
          {CreateValidationAccountUser && (
            <Button
              className={clsx("user-create-button", classes.createUserBtn)}
              color="primary"
              variant="contained"
              onClick={toggleModal}
              startIcon={
                small ? <AddIcon style={{ marginLeft: "33%" }} /> : <></>
              }
            >
              {!small && "Create User"}
            </Button>
          )}
          <Button
            className={clsx("user-save-button", classes.saveBtn)}
            color="primary"
            variant="contained"
            onClick={onSave}
            disabled={!dirty}
            startIcon={
              small ? <SaveIcon style={{ marginLeft: "33%" }} /> : <></>
            }
          >
            {!small && "Save"}
          </Button>
        </div>
      </>
    );
  };

  const handleUserCreated = (user) => {
    setModalOpen(false);
    onUserCreated(user);
  };

  return (
    <>
      <div
        className={clsx("header-content-container", classes.headerContainer)}
      >
        <SearchBar
          className={clsx("user-search-bar", classes.searchBar)}
          onChange={onSearchChange}
        />
        {isIdValid(validationAccountID) ? getContentByUserType() : <></>}
      </div>
      <ValidationUserFormModal
        identifier="validation-user-modal"
        open={modalOpen}
        cancelled={toggleModal}
        onUserCreated={handleUserCreated}
        validationAccountId={validationAccountID}
      />
    </>
  );
}

Header.defaultProps = {
  dirty: false,
  onSearchChange: () => {},
  accountUsersClicked: () => {},
  onSave: () => {},
  onUserCreated: () => {},
};

Header.propTypes = {
  dirty: PropTypes.bool,
  onSearchChange: PropTypes.func,
  accountUsersClicked: PropTypes.func,
  onSave: PropTypes.func,
  onUserCreated: PropTypes.func,
};
