import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `2.5px 2.5px 8px ${theme.palette.grey.light}`,
  },
  cardHeader: {
    padding: theme.spacing(2),
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    backgroundColor: theme.palette.header,
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    height: "3.5rem",
    "& input": {
      backgroundColor: theme.palette.background.default,
    },
    "&.left": {},
    "&.right": {},
  },
  cardHeaderButtons: {},
  cardContent: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
}));
