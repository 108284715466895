import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    [theme.breakpoints.up("lg")]: {},
  },
  flexDropdown: {
    flexBasis: "200px",
  },
  clearEndDateBtn: {
    margin: 0,
    padding: 0,
    zIndex: 100,
  },
  statusHeader: {},
  statusHistoryBtn: {},
  statusContainer: {
    flex: 1,
    display: "inline-flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .flex-input": { width: "100%" },
    },
  },
}));
