import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import {
  Button,
  Grid,
  Tab,
  Typography
} from "@material-ui/core";
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import clsx from "clsx";
import _ from "lodash";
import Add from "@material-ui/icons/Add";
import ThresholdsForm from "../../Forms/AccessGroup/Thresholds";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";

const ThresholdsPanel = ({
  control,
  errors,
  thresholdFields,
  availableRates,
  watch
}) => {

const { showDialog, textConfirmation } = useConfirmationDialog();
const [selectedTab, setSelectedTab] = useState('1');
const [tabs, setTabs] = useState(thresholdFields?.fields?.map((field, index) => { return {value:`${field.threshold}`, label:`Threshold ${field.threshold}`}})) 
const [panels, setPanels] = useState(  thresholdFields?.fields?.map((field, index) => { return {value:`${field.threshold}`}})) 
const [tabIndex, setTabIndex] = useState(1)
const classes = useStyles();

const handleTabChange = (event, newValue) => {
  if (newValue === "0") {
    createNewTab();
  } else {  
    setSelectedTab(newValue);
  }
}

const noRateItem = { 
  rateBlobID: null,
  name: `<No Rate>`,
  rateID: 0
}

const createNewTab = () => {
  const newIndex = tabs.length + 1
  const newTab = {
    value: `${newIndex}`,
    label: `Threshold ${newIndex}`
  }

  setTabs([...tabs, newTab])

  setPanels([
    ...panels,
    {
      value: `${newIndex}`
    }
  ])

  thresholdFields.append({
    thresholdID: null, 
    threshold: `${newIndex}`, 
    thresholdLimit: 1, 
    keepInThreshold: false, 
    rateBlobID: null,
    current: 0});

  setSelectedTab(`${newIndex}`) 
  setTabIndex(newIndex)
}

const handleDelete = async (deleteIndex) => {
  if (thresholdFields.fields[deleteIndex].current !== 0) {
    const alertCantDelete = await showDialog({
      title: `Threshold Delete`,
      message: `You are not allowed to delete a threshold with active parkers`,
      buttons: [
        { name: "Ok", color: "secondary" }
      ],
    });
  } else {  
    const response = await textConfirmation({
      title: "Delete Confirmation",
      message: `You are about to delete a threshold. If you're sure you want to delete it, please enter the threshold name in the input box below.`,
      textConfirmation: tabs[deleteIndex].label,
    });
    if (!response) return;

    let deletePlusOneIndex = deleteIndex + 1;
    
    const newTabs = tabs.filter(x => x.value != `${deletePlusOneIndex}`);
    setTabs(newTabs);

    const newPanels = panels.filter(x => x.value != `${deletePlusOneIndex}`);
    setPanels(newPanels);

    thresholdFields.remove(deleteIndex);

    setSelectedTab(`${deleteIndex}`);
    setTabIndex(deleteIndex);
  }
};

return (
  <Grid className={clsx(classes.thresholdRatesContainer)}>
  <Typography className={clsx("thresholds")} variant="h5">
    Thresholds
  </Typography>
  <TabContext value={selectedTab}>
    <Grid>
      <TabList variant="scrollable" scrollButtons="on"  onChange={handleTabChange} className={clsx("tab-list", classes.tabList)}>
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} className={clsx("tabs", `tab-${tab.value}`, classes.tabs)} data-testid={`tab${tab.value}`} />
        ))}
        <Tab value="0" icon={<Add />} className={clsx("add-tab")}>
          <Button variant="outlined" onClick={createNewTab} />
        </Tab>
      </TabList>
    </Grid>
    {panels.map(panel => (
      <TabPanel key={panel.value} value={panel.value} className={clsx("tab-panel", `tab-panel-${panel.value}`, classes.tabPanel)}>
        <ThresholdsForm 
          control={control}
          errors={errors}
          thresholdFields={thresholdFields}
          fieldsIndex={panel.value - 1}
          maxIndex={panels.length - 1}
          availableRates={[noRateItem,...availableRates]}
          currentRate={thresholdFields.fields[panel.value - 1]?.rateBlobID}
          handleDelete={handleDelete}
          watch={watch}
          /> 
      </TabPanel>
    ))}
  </TabContext>
</Grid>
 );
};

ThresholdsPanel.propTypes = {
  thresholdFields: PropTypes.array
};

export default ThresholdsPanel;
