import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
  },
  flexInput: { margin: theme.spacing(1), flexGrow: "1" },
  groupContractDetails: { flexBasis: "100%" },
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem"
    }
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "60%"
    },
  },
  viewContractBtn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 5,
      paddingLeft: 13,
      height: "30px",
      justifyContent: "left",
      alignItems: "center"
    },
  },
  detailsContainer: {
    flex: 1,
    display: "inline-flex",
    float: "left",
    spaceBetween: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& .flex-input": { width: "100%" },
    }
  },
  textField: {
    margin: theme.spacing(1),
    width: "30%"
  }
}))