import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(1),
    },
    flexInput: {margin: theme.spacing(1), flexGrow: "1"},
    hotelDetails:{flexBasis: "100%"},
    detailsContainer: {
        flex: 1,
        display: "inline-flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
          display: "block",
          "& .flex-input": { width: "100%" },
        },
      },
}))