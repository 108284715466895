import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  taxRateFormControl: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {},
  expansionPanel: {
    display: "flex",
  },
  panelSummary: {
    height: "55px",
  },
  taxRateRow: {
    paddingLeft: "0px",
  },
  taxRateList: {
    maxHeight: "400px",
    overflow: "auto",
    width: "100%",
  },
  taxRateAvatar: { marginRight: theme.spacing(2), marginLeft: theme.spacing(2) },
  taxRateInfo: {},
  taxRateName: {
    fontWeight: "bold",
  },
}));
