import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    flex: "1",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title: {
    width: "100%",
  },
  btnGroup: {
    height: '2rem',
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      "& button": {
        marginLeft: "unset",
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },

  searchBar: {
    marginTop: theme.spacing(-1),
    maxWidth: "300px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  accessHolderList: {
    width: "100%",
    maxHeight: "400px",
    overflowY: "auto",
  },
}));
