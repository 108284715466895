import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import {
  List,
  Grid,
  ListItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";
import * as _ from "lodash";
import clsx from "clsx";
import useHasPermissions from "../../../hooks/useHasPermissions";

const taxSettingsMasterList = [
  {
    settingName: "beforediscount",
    displayName: "Before Discount",
    settingValue: "false",
    dataType: "boolean",
    disabled: false
  },
  {
    settingName: "reverse",
    displayName: "Reverse",
    settingValue: "false",
    dataType: "boolean",
    disabled: false
  }
];

const TaxSettingsPanel = ({ selected, onChange, className }) => {
  const classes = useStyles();
  const selectedRef = useRef(selected);
  const [taxSettings, setTaxSettings] = useState(
    _.cloneDeep(taxSettingsMasterList)
  );
  const { hasPermissions } = useHasPermissions();
  const taxesWrite = hasPermissions(["taxes.write"]);

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
    }

    initializeTaxSettings();
  }, [selected]);

  const handleTaxSettingChanged = (e, setting) => {
    const tempTaxSettings = updateTaxSettings(e, setting);
    setTaxSettings(tempTaxSettings);
    onChange(tempTaxSettings);
  };

  const initializeTaxSettings = () => {
    // Make deep copy of state tax setting list so the it doesnt get modified.
    let tempTaxSettings = _.cloneDeep(taxSettings);
    if (selected != undefined && selected.length > 0) {
      tempTaxSettings.forEach((setting, index) => {
        let foundSetting = selected.find(
          e => e.settingName == setting.settingName
        );
        if (foundSetting != undefined) {
          setting.settingValue = foundSetting.settingValue;
          setting.disabled = taxesWrite ? setting.disabled : "true";
        }
      });

      setTaxSettings(tempTaxSettings);
    }
  };

  const updateTaxSettings = (e, setting) => {
    let tempTaxSettings = taxSettings;

    if (setting.dataType == "boolean") {
      let foundSetting = tempTaxSettings.find(
        e => e.settingName == setting.settingName
      );
      foundSetting.settingValue = e == "false" ? "true" : "false";
    }

    return tempTaxSettings;
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.taxSettingFormControl, className)}
    >
      <Accordion>
        <AccordionSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Tax Settings</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanel}>
          <Grid container direction="column">
            <Grid item>
              <List className={classes.taxSettingList}>
                {taxSettings.map((setting, index) => {
                  return (
                    <ListItem
                      key={setting.settingName}
                      className={classes.taxSettingRow}
                    >
                      <Checkbox
                        id="setting.settingName"
                        color="primary"
                        onChange={e =>
                          handleTaxSettingChanged(e.target.value, setting)
                        }
                        value={setting.settingValue}
                        checked={setting.settingValue == "true"}
                        disabled={setting.disabled == "true"}
                      />
                      <ListItemText className={classes.taxSettingInfo}>
                        <Typography className={classes.taxSettingName}>
                          {setting.displayName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

TaxSettingsPanel.defaultProps = {
  selected: [],
  onChange: () => {}
};

TaxSettingsPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

export default TaxSettingsPanel;
