import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, List } from "@material-ui/core";
import StyledPanel from "../../StyledPanel";
import { useStyles } from "./styles";
import ValidationAccountService from "../../../../services/ValidationAccountService";
import apiClient from "../../../../auth/apiClient";
import ColoredLine from "../../../ColoredLine";
import useHasPermission from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { isUndefined } from "lodash";
import ValidationOfferForm from "../../../Forms/ValidationOffer";

const validationAccountService = new ValidationAccountService(apiClient);

const ValidationOffersPanel = ({
  className,
  entityID,
  validationAccountData,
  contractHolderID,
}) => {
  const classes = useStyles();
  const [validationOffers, setValidationOffers] = React.useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const validationOffersRead = useHasPermission(["validationaccounts.view"]);
  const validationOffersAdd = useHasPermission(["validationaccounts.add"]);

  useEffect(() => {
    fetchValidationOffers();
  }, [contractHolderID, entityID]);

  const fetchValidationOffers = useCallback(async () => {
    if (isUndefined(contractHolderID) || isUndefined(entityID)) {
      setValidationOffers();
      return;
    }

    let response;
    try {
      response = await validationAccountService.getMerchantValidationOffers(
        contractHolderID,
        entityID
      );
      setValidationOffers(response.data);
    } catch {
      enqueueSnackbar("Failed to retrieve validation offers", {
        variant: "error", tag: "FailedToRetrieveValidationOffers"
      });
      return;
    }
  }, [contractHolderID, entityID]);

  const handleDeleteValidationOffer = async (validationOfferID) => {
    let tmpValidationOffers = validationOffers.filter(
      (x) => x.id !== validationOfferID
    );
    setValidationOffers(tmpValidationOffers);
  };

  return (
    <StyledPanel
      className={className}
      headerContent={
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography>Validation Offers</Typography>
          </div>
        </div>
      }
      cardContent={
        validationOffersRead && (
          <div className={classes.panelContent}>
            <List className={classes.credentialList}>
              {validationOffers?.map((valOffer, index) => {
                return (
                  <>
                    <ValidationOfferForm
                      key={valOffer.id}
                      index={index}
                      data-id={`validation-offer-${index}`}
                      validationAccountData={validationAccountData}
                      data={valOffer}
                      inline
                      onDelete={handleDeleteValidationOffer}
                      onSubmit={fetchValidationOffers}
                      contractHolderID={contractHolderID}
                    />
                    <ColoredLine color={"gray"} weight={"skinny"} />
                  </>
                );
              })}
              {validationOffersAdd && (
                <ValidationOfferForm
                  data-id={"validation-offer-create"}
                  validationAccountData={validationAccountData}
                  inline
                  onDelete={handleDeleteValidationOffer}
                  onSubmit={fetchValidationOffers}
                  contractHolderID={contractHolderID}
                />
              )}
            </List>
          </div>
        )
      }
    />
  );
};

ValidationOffersPanel.propTypes = {
  className: PropTypes.string,
  validationAccountData: PropTypes.object,
  entityID: PropTypes.string,
};

export default ValidationOffersPanel;
