import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    "& p": {
      margin: theme.spacing(1),
      paddingTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "unset",
      display: "block",
    },
  },
  accountNumberInput: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  validationNameInput: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  lineBreak: { marginTop: theme.spacing(2) },
  validationAccountTitle: {
    color: theme.palette.white?.main,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    width: "fit-content",
  },
  headerButtons: {
    display: "block",
    marginTop: theme.spacing(-1),
  },
  deleteBtn: {
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      marginBottom: theme.spacing(1),
    },
  },
  noPad: {
    padding: "0 !important",
    outline: "none !important",
  },
  mobileBtn: {
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
  contractDropdown: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.white?.main,
    minWidth: "200px",
    height: "40px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
    },
  },
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    justify: "center",
    flex: 1,
    boxSizing: "border-box",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "20px",
    },
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  permissionOptions: {
    display: "flex"
  },
  contactForm: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      "& [data-id=name-container]": {
        flex: 1,
        flexBasis: "100%",
      },
      "& [data-id=address-container]": {
        flex: 1,
        flexBasis: "100%",
      },
      "& [data-id=email-container]": {
        width: "50%",
      },
      "& [data-id=phone-container]": {
        width: "50%",
      },
    },
  },
  root: { display: "flex", flexDirection: "column" },
  top: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
}));
