import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import { List, ListItem, Grid, Checkbox } from "@material-ui/core";
import useTaxRatesContext from "../../../hooks/useTaxRatesContext";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import SearchBar from "../../SearchBar";
import * as _ from "lodash";
import clsx from "clsx";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const TaxRatesPanel = ({ selected, onChange, className }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { getPageableTaxRates } = useTaxRatesContext();
  const [taxRates, setTaxRates] = useState({ total: 0, collection: [] });
  const [selectedTaxRateIDs, setSelectedTaxRateIDs] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const selectedRef = useRef(selected);
  const { hasPermissions } = useHasPermissions();
  const taxesWrite = hasPermissions(["taxes.write"]);

  useEffect(() => {
    getPageableTaxRates(facilityID, 50, 0, searchTerm)
      .then(res => {
        if (res.status === 200) {
          setTaxRates({
            collection: res.data.collection,
            total: res.data.totalCount
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to get tax rates", {
          variant: "error",
          tag: "FailedToGetTaxRates"
        });
      });
  }, [getPageableTaxRates, searchTerm, facilityID]);

  const fillSelectedTaxRateIDs = selectedTaxRates => {
    let tmpTaxRateIDs = [];
    if (selectedTaxRates != undefined) {
      for (var i = 0; i < selectedTaxRates.length; ++i) {
        tmpTaxRateIDs.push(selectedTaxRates[i].taxRateID);
      }
    }
    setSelectedTaxRateIDs(tmpTaxRateIDs);
  };

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
      fillSelectedTaxRateIDs(selected);
    }
  }, [selected]);

  const handleSearchChange = term => {
    setSearchTerm(term);
  };

  const handleTaxRateChecked = e => {
    const taxRateID = parseInt(e.target.value);
    let tmpTaxRateIDs = [...selectedTaxRateIDs];
    if (tmpTaxRateIDs.includes(taxRateID)) {
      // remove
      tmpTaxRateIDs.splice(tmpTaxRateIDs.indexOf(taxRateID), 1);
    } else {
      // add
      tmpTaxRateIDs.push(taxRateID);
    }

    let tempSelectedTaxRates = [];
    tmpTaxRateIDs.forEach(element => {
      let taxRate = taxRates.collection.find(x => x.taxRateID == element);
      if (taxRate != undefined) {
        tempSelectedTaxRates.push(taxRate);
      }
    });
    onChange(tempSelectedTaxRates);
    setSelectedTaxRateIDs(tmpTaxRateIDs);
  };

  const isChecked = taxRateID => {
    return selectedTaxRateIDs.includes(taxRateID);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.taxRateFormControl, className)}
    >
      <Accordion>
        <AccordionSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Tax Rates</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanel}>
          <Grid container direction="column">
            <Grid item>
              <SearchBar
                className={classes.searchBar}
                delay={100}
                placeholder={`Search ${taxRates.total} tax rates`}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item>
              <List className={classes.taxRateList}>
                {taxRates.collection.map((taxRate, index) => {
                  let checked = isChecked(taxRate.taxRateID);
                  return (
                    <ListItem key={index} className={classes.taxRateRow}>
                      <Checkbox
                        disabled={!taxesWrite}
                        color="primary"
                        onChange={handleTaxRateChecked}
                        value={taxRate.taxRateID}
                        checked={checked}
                      />
                      <div className={classes.taxRateInfo}>
                        <Typography className={classes.taxRateName}>
                          {taxRate.description + ` (${taxRate.taxAmount}%)`}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

TaxRatesPanel.defaultProps = {
  selected: [],
  onChange: () => {}
};

TaxRatesPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

export default TaxRatesPanel;
