import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ItemList from "../List/ItemList";
import { useStyles } from "./styles";
import Pagination from "@material-ui/lab/Pagination";
import { Grid, IconButton, Button, Box, Collapse } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchBar from "../SearchBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useDebounce from "../../hooks/useDebounce";
import Title from "../Title";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Loading from "../Loading";
import clsx from "clsx";

export const PaginatorLocations = {
  TOP: 1,
  BOTTOM: 2,
};

function getRowsByHeight(height) {
  return Math.round(height / 200);
}

const PageableEntity = ({
  addBtnClicked,
  addBtnLabel,
  onPageChange,
  searchBarLabel,
  searchBarPlaceHolder,
  deleteBtnClicked,
  items,
  onItemClicked,
  hideAddBtn,
  hideDeleteBtn,
  hideSearchBar,
  hideFilterBtn,
  disableCheckBoxForID,
  onSearchChange,
  totalCount,
  currentPage,
  searchDebounce,
  title,
  filters,
  defaultSearch,
  paginatorLocation,
  onItemLimitChange,
  disableRowSelect,
  isLoading,
  searchValue,
  className,
  dataID,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [checkedItems, setCheckedItems] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const smallWindow = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchTerm, setSearchTerm] = useState(defaultSearch);
  const debouncedSearchTerm = useDebounce(searchTerm, searchDebounce);
  const refTerm = useRef(debouncedSearchTerm);
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (height) {
      onItemLimitChange(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    if (
      debouncedSearchTerm !== refTerm.current &&
      (debouncedSearchTerm || debouncedSearchTerm === "")
    ) {
      refTerm.current = debouncedSearchTerm;
      onSearchChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (text) => {
    setSearchTerm(text);
  };

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  const toggleFilters = () => {
    if (filters) {
      setFiltersOpen(!filtersOpen);
    }
  };

  const handleDeleteClick = () => {
    deleteBtnClicked(checkedItems);
    setCheckedItems([]);
  };

  const handleItemClicked = (id) => {
    let tmpCheckedItems = checkedItems;
    if (tmpCheckedItems.includes(id)) {
      tmpCheckedItems.splice(tmpCheckedItems.indexOf(id), 1);
    } else {
      tmpCheckedItems.push(id);
    }

    setCheckedItems(tmpCheckedItems);
  };

  return (
    <Box className={clsx("pageable-entity", className)} data-id={dataID}>
      {title && <Title>{title}</Title>}
      <Grid container spacing={2} className={clsx(classes.header)}>
        <Grid item xs={6} lg={9}>
          {hideSearchBar === false && (
            <SearchBar
              className={clsx("pageable-search-bar")}
              placeholder={
                searchBarPlaceHolder ? searchBarPlaceHolder : "Search..."
              }
              label={searchBarLabel}
              delay={850}
              onChange={handleSearchChange}
              defaultSearchTerm={defaultSearch}
              value={searchValue}
            />
          )}
        </Grid>
        {hideDeleteBtn === false && (
          <IconButton
            className={clsx("pageable-delete-button")}
            color="secondary"
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="large" className={clsx(classes.icon)} />
          </IconButton>
        )}
        <IconButton
          className={clsx("pageable-filter-button")}
          color="primary"
          onClick={toggleFilters}
        >
          {hideFilterBtn === false && (
            <FilterListIcon
              data-testid="filter-list-icon"
              fontSize="large"
              className={clsx(classes.icon)}
            />
          )}
        </IconButton>
        {hideAddBtn === false && (
          <Grid item>
            {smallWindow ? (
              <Button
                className={clsx("pageable-add-button", classes.mobileAdd)}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={addBtnClicked}
              />
            ) : (
              <Button
                className={clsx("pageable-add-button")}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={addBtnClicked}
              >
                {addBtnLabel}
              </Button>
            )}
          </Grid>
        )}
        <Grid item xs={12} className={clsx(classes.contentRoot)}>
          <Collapse
            className={clsx(classes.filterContainer)}
            in={filtersOpen}
            unmountOnExit
          >
            {filters}
          </Collapse>
          {totalCount > 1 && paginatorLocation == PaginatorLocations.TOP && (
            <Pagination
              className={clsx("pagination-container")}
              page={currentPage}
              onChange={onPageChange}
              count={totalCount}
              color="primary"
              shape="rounded"
            />
          )}
          {isLoading === true && (
            <Loading className={clsx(classes.loadingSpinner)} />
          )}
          {isLoading === false && (
            <ItemList
              className={clsx("pageable-item-list")}
              items={items}
              selectionChanged={handleItemClicked}
              onRowClicked={(id) => {
                onItemClicked(id);
              }}
              checked={checkedItems}
              hideCheckBox={hideDeleteBtn}
              disableCheckBoxForID={disableCheckBoxForID}
              disableSelect={disableRowSelect}
            />
          )}
          {totalCount > 1 && paginatorLocation == PaginatorLocations.BOTTOM && (
            <Pagination
              className={clsx("pagination-container")}
              page={currentPage}
              onChange={onPageChange}
              count={totalCount}
              color="primary"
              shape="rounded"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

PageableEntity.defaultProps = {
  addBtnClicked: () => {},
  addBtnLabel: "Add Button",
  onPageChange: () => {},
  searchBarLabel: "Search Bar",
  deleteBtnClicked: () => {},
  items: [],
  onItemClicked: () => {},
  hideAddBtn: false,
  hideDeleteBtn: false,
  hideSearchBar: false,
  hideFilterBtn: false,
  hideCheckBoxForID: "",
  onSearchChange: () => {},
  searchDebounce: 500,
  defaultSearch: "",
  paginatorLocation: PaginatorLocations.BOTTOM,
  onItemLimitChange: () => {},
  disableRowSelect: false,
  isLoading: false,
};

PageableEntity.propTypes = {
  addBtnClicked: PropTypes.func,
  addBtnLabel: PropTypes.string,
  onPageChange: PropTypes.func,
  searchBarLabel: PropTypes.string,
  deleteBtnClicked: PropTypes.func,
  items: PropTypes.array,
  onItemClicked: PropTypes.func,
  hideAddBtn: PropTypes.bool,
  hideDeleteBtn: PropTypes.bool,
  hideSearchBar: PropTypes.bool,
  hideFilterBtn: PropTypes.bool,
  hideCheckBoxForID: PropTypes.string,
  onSearchChange: PropTypes.func,
  searchDebounce: PropTypes.number,
  title: PropTypes.string,
  filters: PropTypes.node,
  defaultSearch: PropTypes.string,
  paginatorLocation: PropTypes.number,
  onItemLimitChange: PropTypes.func,
  disableRowSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PageableEntity;
