import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import ExpansionPanelSummaryLeftIcon from "../ExpansionPanelSummaryLeftIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./styles";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import _ from "lodash";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import SearchBar from "../../SearchBar";
import apiClient from "../../../auth/apiClient";
import TreeService from "../../../services/TreeService";
import useAuthContext from "../../../hooks/useAuthContext";
import UserService from "../../../services/UserService";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { FLAG_PERMISSIONS } from "../../../constants/FlagPermissions";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";

const treeService = new TreeService(apiClient);
const userService = new UserService(apiClient);

const ACCESS_TYPES = ["view", "edit", "add", "delete"];

const sortPermissions = (permissions) => {
  let accessTypeArr = [],
    otherPerms = [];
  // regular access types first
  permissions.forEach((permission) => {
    if (ACCESS_TYPES.includes(permission.friendlyName.toLowerCase()))
      accessTypeArr.push(permission);
    else otherPerms.push(permission);
  });
  accessTypeArr = accessTypeArr.sort((x, y) =>
    x.friendlyName < y.friendlyName ? 1 : -1
  );

  // then sort by permission name alphabetically
  otherPerms = otherPerms.sort((x, y) =>
    x.friendlyName > y.friendlyName ? 1 : -1
  );
  return [...accessTypeArr, ...otherPerms];
};

const semanticallyGroup = (list, listGroup) => {
  const sortedChildrenFirst = list.sort((x, y) =>
    x.semanticGroupID < y.semanticGroupID ? 1 : -1
  );
  const groupLookup = {},
    tree = [];

  // build group lookup so we have the shape of the whole tree for what permissions the user has available
  sortedChildrenFirst.forEach((item) => {
    if (!groupLookup[item.semanticGroupID])
      groupLookup[item.semanticGroupID] = {
        groupID: item.semanticGroupID,
        parentID: item.parentSemanticGroupID,
        groupName: item.semanticGroupName,
        children: [],
        permissions: [
          {
            permissionID: item.permissionID,
            permissionName: item.permissionName,
            friendlyName: item.friendlyName,
          },
        ],
      };
    else {
      const permissions = groupLookup[item.semanticGroupID].permissions;
      permissions.push({
        permissionID: item.permissionID,
        permissionName: item.permissionName,
        friendlyName: item.friendlyName,
      });
      groupLookup[item.semanticGroupID].permissions = sortPermissions(
        permissions
      );
      // update the parent id again in case we traverse over a higher node in which we didn't know the parent of at the time
      groupLookup[item.semanticGroupID].parentID = item.parentSemanticGroupID;
    }

    // now build the parent node if not already a root node
    let parentSemanticGroup = listGroup.find(x => x.groupID === item.parentSemanticGroupID);
    const parentSemanticGroupDepth = parentSemanticGroup?.depth;
    if (parentSemanticGroup !== undefined){
      for (let depth = parentSemanticGroupDepth ?? 0; depth >= 0; depth--) {
        if (parentSemanticGroup.groupID !== null &&
          !groupLookup[parentSemanticGroup.groupID]) {
            groupLookup[parentSemanticGroup.groupID] = {
              groupID: parentSemanticGroup.groupID,
              groupName: parentSemanticGroup.groupName,
              parentID: parentSemanticGroup.parentGroupID,
              children: [],
              permissions: [],
            };
          }
          parentSemanticGroup = listGroup.find(x => x.groupID === parentSemanticGroup.parentGroupID);
      }
    }
  });

  Object.values(groupLookup).forEach((item) => {
    if (!item.parentID) tree.push(item);
    else {
      const foundParent = groupLookup[item.parentID];
      foundParent.children.push(item);
    }
  });

  return tree;
};

const PermissionPanel = ({
  inheritedPermissions,
  selected,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [selectedPermissions, setSelectedPermissions] = useState(selected);
  const [permissionsList, setPermissionList] = useState([]);
  const [permissionsGroupList, setPermissionGroupList] = useState([]);
  const [filterablePermissions, setFilterablePermissions] = useState([]);
  const [permissionsSelectAll, setPermissionsSelectAll] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const enqueueSnackbar = useEnqueueSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const { authReducer } = useAuthContext();
  const [authState] = authReducer;
  const currentUserID = authState.userSession?.idToken?.payload?.userid;
  const selectedRef = useRef(selected);
  const featureFlags = useFlags();

  useEffect(() => {
    async function work() {
      await getFacilityName(scopeAwareFacilityID);
      await getPermissions(scopeAwareFacilityID, currentUserID);
    }
    work();
  }, [currentUserID, scopeAwareFacilityID]);

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
      setSelectedPermissions(selected);
    }
  }, [selected, permissionsList]);

  const initializePermissionSelectAll = useCallback(() => {
    const tmpSelected = selectedPermissions;
    
    if (selectedPermissions.length === 0) {
      return false;
    }
    for (var i = 0; i < permissionsList.length; i++) {
      if (
        !tmpSelected.includes(permissionsList[i].permissionName) &&
        !inheritedPermissions.includes(permissionsList[i].permissionName)
        ) {
          return false;
        }
      }
      
      return true;
    }, [selectedPermissions, permissionsList, inheritedPermissions]);
    
    useEffect(() => {
      setPermissionsSelectAll(initializePermissionSelectAll());
    }, [initializePermissionSelectAll]);
  
  const getFacilityName = useCallback(async () => {
    treeService
      .getTreeStructure(scopeAwareFacilityID, 1)
      .then((result) => {
        setFacilityName(result.data[0].entityname);
      })
      .catch((err) => {
        setFacilityName("");
      });
  }, [scopeAwareFacilityID]);

  const getPermissions = useCallback(
    async (entityID, userID) => {
      let response;
      try {
        response = await userService.getScopedPermissions(userID, entityID);
      } catch {
        enqueueSnackbar("Failed to retrieve permissions", { variant: "error", tag: "FailedToRetrievePermissions" });
        return;
      }

      /* facilityID is used here because when a FG is passed in, permissions for all facilities are returned,
         and we only want the current facility
      */
      let scoped = response.data?.find((x) => x.entityID === facilityID);
      if (scoped && scoped.permissions) {
        setPermissionList(scoped.permissions);
        setPermissionGroupList(scoped.permissionGroups);
        setFilterablePermissions(semanticallyGroup(scoped.permissions, scoped.permissionGroups));
      } else {
        setPermissionList([]);
        setFilterablePermissions([]);
      }
    },
    [scopeAwareFacilityID, currentUserID]
  );

  const isPermissionDisabled = (permission) => {
    return (
      inheritedPermissions.filter((x) => x.includes(permission))?.length > 0
    );
  };

  const isPermissionHidden = (permission) => {
    var keys = Object.keys(FLAG_PERMISSIONS);
    var isHidden = false;
    keys.forEach((x) => {
      if (FLAG_PERMISSIONS[x].includes(permission)) isHidden = !featureFlags[x];
    });
    return isHidden;
  };

  const isChecked = (permission) => {
    return (
      isPermissionDisabled(permission) ||
      selectedPermissions.includes(permission)
    );
  };

  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    const tmpPermissions = permissionsList?.slice() ?? [];
    const filtered = tmpPermissions.filter(
      (x) =>
        x.permissionName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        x.friendlyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        x.semanticGroupName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilterablePermissions(semanticallyGroup(filtered, permissionsGroupList));
  };

  const handlePermissionChecked = (e) => {
    const permission = e.target.value;
    let tmpSelected = selectedPermissions?.slice() ?? [];
    const foundIndex = tmpSelected.findIndex((x) => x === permission);
    if (foundIndex < 0) {
      tmpSelected.push(permission);
    } else {
      tmpSelected.splice(foundIndex, 1);
    }

    setSelectedPermissions([...tmpSelected]);
    onChange(tmpSelected);
  };

  const handleSelectAll = () => {
    // ensure that checkboxes are not modified if inherited
    let tmpPermissions = permissionsList?.slice() ?? [];
    if (!permissionsSelectAll === true) {
      tmpPermissions = tmpPermissions
        .filter((x) => !inheritedPermissions.includes(x))
        ?.map((x) => x.permissionName);
      setSelectedPermissions([...tmpPermissions]);
      onChange(tmpPermissions);
    } else {
      setSelectedPermissions([...inheritedPermissions]);
      onChange(inheritedPermissions);
    }
    setPermissionsSelectAll(!permissionsSelectAll);
  };

  const renderPermissionTree = useCallback(
    (permissions) => {
      const getPermissionBoxes = (permissions) => {
        return permissions.map((permission, index) => {
          const { permissionName, friendlyName } = permission;
          const checked = isChecked(permissionName);
          const isDisabled = isPermissionDisabled(permissionName);
          if (isPermissionHidden(permissionName)) return;
          return (
            <div key={index} className={clsx(classes.permissionContainer)}>
              <Grid
                className={clsx(`permission-row-${permissionName}`)}
                container
                justify="space-between"
              >
                <Grid item>
                  <Box
                    fontSize={18}
                    lineHeight={2.5}
                    className={clsx(`${friendlyName}`)}
                  >
                    {friendlyName}
                  </Box>
                </Grid>
                <Grid item>
                  <Checkbox
                    className={clsx(
                      "permission-row-checkbox",
                      classes.permission
                    )}
                    color="primary"
                    onChange={handlePermissionChecked}
                    value={permissionName}
                    checked={checked}
                    disabled={isDisabled || disabled}
                  />
                </Grid>
              </Grid>
              <Divider />
            </div>
          );
        });
      };

      const getNestedView = (permissions, children) => {
        return (
          <>
            {getPermissionBoxes(permissions)}
            {children?.map(({ groupName, permissions, children }, index) => {
              return (
                !isPermissionHidden(groupName) &&
                  <Accordion
                    key={index}
                    className={clsx(
                      `${groupName}-children-panel`,
                      classes.borderlessPanel
                    )}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <ExpansionPanelSummaryLeftIcon
                      className={clsx(
                        "expansion-children-panel-icon",
                        classes.panelSummary,
                        classes.pullLeft
                      )}
                      expandIcon={<ChevronRightIcon />}
                    >
                      <Box
                        fontSize={18}
                        fontWeight="bold"
                        className={clsx(`${groupName}-children`)}
                      >
                        {groupName}
                      </Box>
                    </ExpansionPanelSummaryLeftIcon>
                    <AccordionDetails
                      className={clsx("children-details", classes.flexColumn)}
                    >
                      {getNestedView(permissions, children)}
                    </AccordionDetails>
                  </Accordion>
              );
            })}
          </>
        );
      };

      return permissions.map((permission, index) => {
        const { groupName, permissions, children } = permission;
        return (
          !isPermissionHidden(groupName) &&
            <Accordion
              key={index}
              className={clsx(
                `${groupName}-permissions-panel`,
                classes.borderlessPanel
              )}
              TransitionProps={{ unmountOnExit: true }}
            >
              <ExpansionPanelSummaryLeftIcon
                className={clsx(
                  "expansion-permissions-panel-icon",
                  classes.panelSummary,
                  classes.pullLeft
                )}
                expandIcon={<ChevronRightIcon />}
                IconButtonProps={{ edge: "start" }}
              >
                <Box
                  fontSize={18}
                  fontWeight="bold"
                  className={clsx(`${groupName}-permissions`)}
                >
                  {groupName}
                </Box>
              </ExpansionPanelSummaryLeftIcon>
              <AccordionDetails
                className={clsx("permissions-details", classes.flexColumn)}
              >
                {getNestedView(permissions, children)}
              </AccordionDetails>
            </Accordion>
        );
      });
    },
    [
      filterablePermissions,
      searchTerm,
      selectedPermissions,
      permissionsSelectAll,
    ]
  );

  const handleDefaultAllChecked = useCallback(() => {
    const tmpSelected = selectedPermissions;
    for (var i = 0; i < permissionsList.length; i++) {
      if (
        !tmpSelected.includes(permissionsList[i].permissionName) &&
        !inheritedPermissions.includes(permissionsList[i].permissionName)
      ) {
        return false;
      }
    }

    return true;
  }, [permissionsList, selected, inheritedPermissions, selectedPermissions]);

  return (
    <Accordion className={clsx("permission-panel", classes.container)}>
      <AccordionSummary
        className={clsx("permission-panel-expand-more", classes.panelSummary)}
        expandIcon={<ExpandMoreIcon />}
        IconButtonProps={{ edge: "start" }}
      >
        <Typography className={clsx(`permissions-for-${facilityName}`)}>
          Permissions for {facilityName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={clsx("permissions-accordion-details", classes.rootDetails)}
      >
        <Grid container spacing={4} direction="column">
          <Grid item>
            <SearchBar
              className={clsx("permission-search-bar", classes.searchBar)}
              placeholder={`Search permissions`}
              onChange={handleSearchChange}
            />
          </Grid>
          {Object.keys(filterablePermissions)?.length === 0 ? (
            <Typography
              className={clsx(
                "permissions-not-found",
                classes.noPermissionsMessage
              )}
            >
              No Permissions Found
            </Typography>
          ) : (
            <>
              <Grid item>
                <div className={clsx(classes.selectAllContainer)}>
                  <Box
                    className={clsx("select-all", classes.selectAllBox)}
                    fontSize={18}
                    fontWeight="bold"
                  >
                    Select All
                  </Box>
                  <Checkbox
                    className={clsx(
                      "select-all-checkbox",
                      classes.selectAllCheckbox
                    )}
                    color="primary"
                    onClick={handleSelectAll}
                    checked={handleDefaultAllChecked()}
                    disabled={disabled}
                  />
                </div>
              </Grid>
              <Grid item className={clsx("permission-tree")}>
                {renderPermissionTree(filterablePermissions)}
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

PermissionPanel.defaultProps = {
  selected: [],
  inheritedPermissions: [],
  onChange: () => {},
  disabled: false,
};

PermissionPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  inheritedPermissions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PermissionPanel;
