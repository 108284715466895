import React, { useState, useEffect, useCallback } from 'react';
import StyledPanel from '../../StyledPanel';
import { useStyles } from './styles';
import { TextField, Button, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import apiClient from '../../../../auth/apiClient';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import GroupContractService from '../../../../services/GroupContractService';
import PropTypes from 'prop-types';
import useHasPermissions from '../../../../hooks/useHasPermissions';
import { useViewGroupContract } from '../../../../hooks/useViewGroupContract';

const groupContractService = new GroupContractService(apiClient);

const GroupContractDetailsPanel = ({
  className,
  contractID,
  setIsGroupContract
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState();
  const [hasDetails, setHasDetails] = useState(false);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const { hasPermissions } = useHasPermissions();
  const viewGroupContract = hasPermissions(['groupcontracts.view']);
  const { handleViewGroupContract } = useViewGroupContract()

  const fetchPanelInfo = useCallback(
    async (contractID) => {
      try {
        let response = await groupContractService.getGroupContractSlim(contractID);
        return response.data;
      } catch {
        return undefined;
      }
    },
    [contractID]
  );

  useEffect(() => {
    if (contractID) {
      fetchPanelInfo(contractID).then((info) => {
        setDetails(info);
      });
    }
  }, [contractID, fetchPanelInfo]);

  const viewContract = (id) => {
    handleViewGroupContract(id)
  };

  useEffect(() => {
    if (!isUndefined(details) && !isUndefined(details.accountNumber)) {
      setHasDetails(true);
      setIsGroupContract(true);
    }
  }, [details, setHasDetails]);

  if (hasDetails) {
    return (
      <StyledPanel
        className={clsx("groupContractDetails", className)}
        data-testid="group-contract-details-panel"
        headerContent={
          <div className={clsx("header", classes.header)}>
            <Typography className={clsx("header-left", classes.headerLeft)}>Group Contract</Typography>
            {viewGroupContract && (
              <Button
                className={clsx("btn-acc-view", classes.viewContractBtn)}
                data-testid="view-group-contract"
                variant="contained"
                color="primary"
                onClick={() => viewContract(contractID)}
              >
                {small ? "View" : "View Group Contract"}
              </Button>
            )}
          </div>
        }
        cardContent={
          <div className={clsx("groupContractDetails", classes.detailsContainer)} container>
            <TextField
              className={clsx("contract-name", "flex-input", classes.textField)}
              data-testid="contract-name"
              id="contract-name"
              name="contract-name"
              label="Account Name"
              aria-readonly="true"
              value={details?.name || ""}
            />
            <TextField
              className={clsx(
                'account-number',
                'flex-input',
                classes.textField
              )}
              data-testid="account-number"
              id="account-number"
              name="account-number"
              label="Account Number"
              aria-readonly="true"
              value={details?.accountNumber || ""}
            />
          </div>
        }
      />
    );
  } else {
    return <></>;
  }
};

GroupContractDetailsPanel.defaultProps = {
  setIsGroupContract: () => {},
};
GroupContractDetailsPanel.propTypes = {
  className: PropTypes.string,
  contractID: PropTypes.string,
  entityID: PropTypes.string,
  setIsGroupContract: PropTypes.func,
};

export default GroupContractDetailsPanel;
