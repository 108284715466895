import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  noPermissionsMessage: {
    margin: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  selectAllContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  selectAllBox: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  selectAllCheckbox: {
    paddingRight: theme.spacing(0.5),
  },
  panelSummary: {
    height: "55px",
  },
  rootDetails: {
    width: "100%",
  },
  borderlessPanel: {
    width: "100%",
    WebkitBoxShadow: "none",
    MozBoxShadow: "none",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  flexColumn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    flexDirection: "column",
  },
  pullLeft: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  permissionContainer: {
    marginLeft: theme.spacing(2),
    width: "100%",
    margin: 0,
  },
  permission: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
  },
}));
