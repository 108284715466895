import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import { List, ListItem, Grid, Checkbox, Box } from "@material-ui/core";
import useTaxesContext from "../../../hooks/useTaxesContext";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import SearchBar from "../../SearchBar";
import * as _ from "lodash";
import clsx from "clsx";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const TaxesPanel = ({ selected, onChange, className, disabled }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [selectedTaxIDs, setSelectedTaxIDs] = useState([]);
  const selectedRef = useRef(selected);
  const [searchTerm, setSearchTerm] = useState();

  const [taxes, setTaxes] = useState({
    totalCount: 0,
    collection: [],
    display: [],
  });
  const { getPageableTaxes } = useTaxesContext();

  useEffect(() => {
    getPageableTaxes(facilityID, 50, 0, searchTerm)
      .then((res) => {
        if (res.status === 200) {
          setTaxes(res.data);
          formatTaxesData(res.data.collection);
        } else {
          enqueueSnackbar("We encountered a problem retrieving taxes", {
            variant: "error", tag: "FailedToRetrieveTaxes"
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve taxes", {
          variant: "error", tag: "FailedToRetrieveTaxes" 
        });
      });
  }, [getPageableTaxes, searchTerm, facilityID]);
  const formatTaxesData = (rawTaxes) => {
    const formatted = rawTaxes.map((tax) => {
      return {
        id: tax.taxID,
        displayContent: (
          <Grid container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Box fontWeight="bold" fontSize="large">
                  {tax.description}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ),
      };
    });

    setTaxes((prev) => ({ ...prev, display: formatted }));
  };

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
      setSelectedTaxIDs(selected);
    }
  }, [selected]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const handleTaxRateChecked = (e) => {
    const taxRateID = parseInt(e.target.value);
    let tmpTaxRateIDs = [...selectedTaxIDs];
    if (tmpTaxRateIDs.includes(taxRateID)) {
      // remove
      tmpTaxRateIDs.splice(tmpTaxRateIDs.indexOf(taxRateID), 1);
    } else {
      // add
      tmpTaxRateIDs.push(taxRateID);
    }

    onChange(tmpTaxRateIDs);
    setSelectedTaxIDs(tmpTaxRateIDs);
  };

  const isChecked = (taxRateID) => {
    return selectedTaxIDs.includes(taxRateID);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.taxRateFormControl, className)}
    >
      <Accordion expanded={true}>
        <AccordionSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Taxes to apply</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanel}>
          <Grid container direction="column">
            <Grid item>
              <SearchBar
                className={classes.searchBar}
                delay={100}
                placeholder={`Search ${taxes.totalCount} taxes`}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item>
              <List className={classes.taxRateList}>
                {taxes.collection.map((tax, index) => {
                  let checked = isChecked(tax.taxID);
                  return (
                    <ListItem key={index} className={classes.taxRateRow}>
                      <Checkbox
                        color="primary"
                        onChange={handleTaxRateChecked}
                        value={tax.taxID}
                        checked={checked}
                        disabled={disabled}
                      />
                      <div className={classes.taxRateInfo}>
                        <Typography className={classes.taxRateName}>
                          {tax.description}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

TaxesPanel.defaultProps = {
  selected: [],
  onChange: () => {},
  disabled: false,
};

TaxesPanel.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TaxesPanel;
