import React, { useState, useEffect, useCallback } from "react";
import StyledPanel from "../StyledPanel";
import { useStyles } from "./styles";
import { Typography, TextField } from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import HotelAccessHolderService from "../../../services/HotelAccessHolderService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import clsx from "clsx";
import { isUndefined, isNull } from "lodash";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";


const hotelAccessHolderService = new HotelAccessHolderService(apiClient);

const HotelDetailsPanel = ({ className, accessHolderID, entityID }) => {
  const classes = useStyles();
  const [details, setDetails] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();

  useEffect(() => {
    if (accessHolderID) {
      fetchPanelInfo(entityID, accessHolderID).then(info => {
        setDetails(info);
      });
    }
  }, [entityID, accessHolderID]);

  const fetchPanelInfo = useCallback(
    async (entityID, accessHolderID) => {
      try {
        let response = await hotelAccessHolderService.getHotelAccessHolderDetails(
          entityID,
          accessHolderID
        );
        return response.data.extraDetails;
      } catch {
        enqueueSnackbar("Failed to retrieve access holder details info", {
          variant: "error",
          tag: "accessHolderDetailsInfoError"
        });
        return undefined;
      }
    },
    [entityID, accessHolderID]
  );

  function formatDate(dateStr) {
    if(isUndefined(dateStr) || isNull(dateStr)){
      return "";
    }
    return convertUtcDateToFormat(dateStr);
  }

  if (isUndefined(details) || !details?.extraDetailsID === 1) {
    return <></>
  } else {
    return (
      <>
        <StyledPanel
          className={clsx("hotelDetails", className)}
          data-testid="hotel-details-panel"
          headerContent={
            <Typography className={clsx("hotel-details")}>
              Hotel Details
            </Typography>
          }
          cardContent={
            <div
              className={clsx("hotelDetails", classes.detailsContainer)}
              container
            >
              <TextField
                className={clsx(
                  "hotel-name",
                  "flex-input",
                  classes.TextField
                )}
                data-testid="hotel-device"
                id="hotel-name"
                name="hotel-name"
                label="Hotel Name"
                inputProps={{
                  readOnly: true,
                  disabled: true
                }}
                value={details.payload?.HotelName || ""}
              />
              <TextField
                className={clsx("room-number", "flex-input", classes.TextField)}
                data-testid="room-number"
                id="room-number"
                name="room-number"
                label="Room Number"
                inputProps={{
                  readOnly:true,
                  disabled: true
                }}
                value={details.payload?.RoomNumber || ""}
              />
              <TextField
                className={clsx(
                  "check-in-date",
                  "flex-input",
                  classes.TextField
                )}
                data-testid="check-in-date"
                id="check-in-date"
                name="check-in-date"
                label="Check In"
                inputProps={{
                  readOnly:true,
                  disabled: true
                }}
                value={formatDate(details.payload?.CheckInDate) || ""}
              />
              <TextField
                className={clsx(
                  "check-out-date",
                  "flex-input",
                  classes.TextField
                )}
                data-testid="check-out-date"
                id="check-out-date"
                name="check-out-date"
                label="Check Out"
                inputProps={{
                  readOnly:true,
                  disabled: true
                }}
                value={formatDate(details.payload?.CheckOutDate) || ""}
              />
            </div>
          }
        />
      </>
    );
  }
};

export default HotelDetailsPanel;
