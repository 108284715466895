import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { List, ListItem, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isUndefined } from "lodash";
import PropTypes from "prop-types";
import apiClient from "../../../auth/apiClient";
import { CREDENTIAL_TYPE_STRING_VALUE } from "../../../constants";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useSettingContext from "../../../hooks/useSettingsContext";
import { onEmailSettingsChanged } from "../../../reducers/settings/settingsReducer";
import CardSystemCodeService from "../../../services/CardSystemCodesService";
import ContractService from "../../../services/ContractService";
import CredentialService from "../../../services/CredentialService";
import SettingService from "../../../services/SettingsService";
import ColoredLine from "../../ColoredLine";
import AccessCredentialForm from "../../Forms/AccessCredential";
import StyledPanel from "../StyledPanel";
import { useStyles } from "./styles";

const credentialService = new CredentialService(apiClient);
const settingService = new SettingService(apiClient);
const contractService = new ContractService(apiClient);
const cardSystemCodesService = new CardSystemCodeService(apiClient);

const filteredCredentials = (credential) =>
  credential?.credentialType?.toLowerCase() !==
  CREDENTIAL_TYPE_STRING_VALUE.LICENSEPLATE.toLowerCase();

const CredentialsPanel = ({
  className,
  accessHolderID,
  accessHolderEmail,
  contractID,
  facilityID,
  disabled,
}) => {
  const classes = useStyles();
  const [accessCredentials, setAccessCredentials] = React.useState([
    { accessHolderID, contractID },
  ]);
  const [availableCodes, setAvailableCodes] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const credentialsRead = hasPermissions(["accessholders.view", "inventory.view"], true);
  const [accessHolderInfo, setAccessHolderInfo] = useState();
  const { reducer } = useSettingContext();
  const [, dispatch] = reducer;
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityID = facilityGroupId || facilityID;

  useEffect(() => {
    fetchSettings(facilityID);
  }, [facilityID]);

  useEffect(() => {
    if (!isUndefined(accessHolderID) && !isUndefined(facilityID)) {
      fetchAccessCredentials();
      fetchAccessHolder(facilityID, accessHolderID);
    }
  }, [facilityID, accessHolderID, contractID, accessHolderEmail]);

  const fetchSettings = useCallback(async () => {
    try {
      const response = await settingService.getSettings(facilityID);
      dispatch({
        type: onEmailSettingsChanged,
        payload: {
          emailSettings: {
            from: response.data["setting.emailfrom"],
            displayname: response.data["setting.emaildisplayfrom"],
            replyTo: response.data["setting.emailreply"],
            ccReplyTo: response.data["setting.ccreplyto"],
            template: response.data["setting.emailtemplate"],
          },
        },
      });
    } catch {
      enqueueSnackbar("Failed to retrieve email settings");
    }
  }, [facilityID]);

  const fetchAccessHolder = useCallback(
    async (facilityID, accessHolderID) => {
      let response;
      try {
        response = await contractService.getAccessHolder(
          facilityID,
          accessHolderID
        );
      } catch {
        enqueueSnackbar("Failed to retrieve Access Holder", {
          variant: "error",
          tag: "FailedToRetrieveAccessholder",
        });
        return;
      }
      let email;
      if (response.data.contactInfo?.emails) {
        email = response.data.contactInfo.emails[0]?.emailAddress;
      }
      setAccessHolderInfo({
        email,
        firstName: response?.data?.contactInfo?.firstName,
        lastName: response?.data?.contactInfo?.lastName,
      });
    },
    [facilityID, accessHolderID]
  );

  const fetchAccessCredentials = useCallback(async () => {
    if (!credentialsRead || !accessHolderID || !contractID) {
      setAccessCredentials();
      return;
    }

    let response;
    try {
      response = await credentialService.GetAccessCredentialsForAccessHolder(
        accessHolderID,
        contractID,
        100,
        0,
        ""
      );
    } catch {
      enqueueSnackbar("Failed to retrieve credentials", {
        variant: "error",
        tag: "FailedToRetrieveCredentials",
      });
      return;
    }

    let tmp = response.data?.collection ?? [];
    tmp.unshift({ accessHolderID, contractID });
    setAccessCredentials(tmp);
  }, [accessHolderID, contractID, credentialsRead]);

  const getCardCodes = useCallback(async () => {
    try {
      var result = await cardSystemCodesService.getCardSystemCodesForDropdown(
        scopeAwareFacilityID
      );
      setAvailableCodes([...result]);
    } catch (error) {
      enqueueSnackbar("Failed to get card system codes for facility", {
        variant: "error",
        tag: "cardSystemCodeErr",
      });
    }
  }, [facilityID, facilityGroupId]);

  useEffect(() => {
    getCardCodes();
  }, [facilityID, facilityGroupId]);

  const handleRemoveCredential = (credentialID) => {
    let tmpCredentials = accessCredentials;
    tmpCredentials = tmpCredentials.filter(
      (x) => x.credentialID !== credentialID
    );
    setAccessCredentials(tmpCredentials);
  };

  const handleAddCredential = async () => {
    await fetchAccessCredentials();
  };

  return (
    <StyledPanel
      data-testid={"credentialsPanelTestID"}
      className={clsx("credentials-panel", className)}
      headerContent={<Typography>Credentials</Typography>}
      cardContent={
        <List className={clsx("credentials-list", classes.credentialList)}>
          {accessCredentials
            ?.filter(filteredCredentials)
            ?.map((credential, index) => {
              return (
                <>
                  <ListItem
                    key={index}
                    className={clsx(
                      "credentials-list-item",
                      classes.rowContainer,
                      `credential-reference-${credential.credentialReference ??
                        "add-new"}`
                    )}
                  >
                    <AccessCredentialForm
                      className={clsx("credentials-form")}
                      data={{
                        ...credential,
                        accessHolderID,
                        contractID,
                        availableCodes,
                      }}
                      email={accessHolderInfo?.email || accessHolderEmail}
                      inline
                      onDelete={handleRemoveCredential}
                      onSubmit={handleAddCredential}
                      assignedAccessHolderName={{
                        first: accessHolderInfo?.firstName,
                        last: accessHolderInfo?.lastName,
                      }}
                      disabled={disabled}
                    />
                  </ListItem>
                  <ColoredLine />
                </>
              );
            })}
        </List>
      }
    />
  );
};

CredentialsPanel.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
  contractID: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CredentialsPanel;
