import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tabs: {
    minWidth: "100px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightGray",
    "& .Mui-selected": {
        textDecoration: "none",
        backgroundColor: "darkGray",
      },
    fontSize: ".7rem"
  },
  tabPanel: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "lightGray"
  },
  tabList: {
    "& .MuiTabs-indicator": {
        display: "none"
      },
    "& .Mui-selected": {
        textDecoration: "none",
        backgroundColor: "rgba(0,0,0,0.1)",
      },
  },
  thresholdRatesContainer: {
    margin: theme.spacing(1),
  }
}));
