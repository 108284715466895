import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";
import StyledPanel from "../StyledPanel";
import { useStyles } from "./style";
import { generateUUID } from "../../Rate";
import DeleteIcon from "@material-ui/icons/Delete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { isUndefined, isEmpty, isEqual } from "lodash";
import apiClient from "../../../auth/apiClient";
import ContractService from "../../../services/ContractService";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressSubformSchema } from "../../Forms/ContactSubform/AddressSubform";
import { EmailSubformSchema } from "../../Forms/ContactSubform/EmailSubform";
import { PhoneSubformSchema } from "../../Forms/ContactSubform/PhoneSubform";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import ContactSubform from "../../Forms/ContactSubform";
import { useSelector } from "react-redux";

const contractService = new ContractService(apiClient);

const AccessHolderPanel = ({
  className,
  accessHolderID,
  onDelete,
  onChange,
}) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const [accessHolder, setAccessHolder] = useState({
    accessHolderID,
  });
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const accessHolderDelete = hasPermissions(["accessholders.delete"]);
  const accessHolderEdit = hasPermissions(["accessholders.edit"]);
  const accessHolderAdd = hasPermissions(["accessholders.add"]);
  const enqueue = useEnqueueSnackbar();
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  
  const AccessHolderSchema = Yup.object().shape({
    accountNumber: Yup.string().required("* Required"),
    company: Yup.string().nullable(),
    contactInfo: Yup.object().shape({
      firstName: Yup.string().required("* Required"),
      lastName: Yup.string(),
      addresses: Yup.array().of(AddressSubformSchema),
      emails: Yup.array().of(EmailSubformSchema),
      phoneNumbers: Yup.array().of(PhoneSubformSchema),
    }),
  });

  const defaultValues = {
    accountNumber: "",
    company: "",
    contactInfo: {
      firstName: "",
      lastName: "",
      addresses: [
        {
          type: "Home",
          addressLine1: "",
          addressLine2: "",
          countryID: "",
          city: "",
          subdivisionID: "",
          postalCode: "",
        },
      ],
      emails: [{ type: "Personal", emailAddress: "" }],
      phoneNumbers: [{ type: "Mobile", number: "" }],
    },
  };

  const methods = useForm({
    resolver: yupResolver(AccessHolderSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    formState: { isDirty, isSubmitting, errors, isValid },
  } = methods;

  useEffect(() => {
    if (!isUndefined(accessHolder.accessHolderID)) {
      fetchAccessHolder(facilityID, accessHolder.accessHolderID);
    } else {
      const accNumber = generateUUID();
      setAccessHolder((prev) => ({ ...prev, accountNumber: accNumber }));
      reset({ ...defaultValues, accountNumber: accNumber });
    }
  }, [accessHolder.accessHolderID]);

  const setFormValuesFromResponse = (data) => {
    const {
      accountNumber,
      company,
      contactInfo: {
        addresses,
        contactID,
        entityID,
        emails,
        firstName,
        lastName,
        phoneNumbers,
      },
    } = data;
    const formValues = {
      accountNumber: accountNumber,
      company: company,
      contactInfo: {
        addresses:
          addresses?.length > 0
            ? addresses
            : defaultValues.contactInfo.addresses,
        contactID: contactID,
        entityID: entityID,
        emails: emails?.length > 0 ? emails : defaultValues.contactInfo.emails,
        firstName: firstName,
        lastName: lastName,
        phoneNumbers:
          phoneNumbers?.length > 0
            ? phoneNumbers
            : defaultValues.contactInfo.phoneNumbers,
      },
    };
    reset(formValues);
  };

  const fetchAccessHolder = useCallback(
    async (facilityID, accessHolderID) => {
      let response;
      try {
        response = await contractService.getAccessHolder(
          facilityID,
          accessHolderID
        );
      } catch {
        enqueue("Failed to retrieve Access Holder", {
          variant: "error",
          tag: "accessHolderFetchError",
        });
        return;
      }
      onChange(response.data);
      setAccessHolder(response.data);
      setFormValuesFromResponse(response.data);
    },
    [facilityID, accessHolder.accessHolderID]
  );

  async function createOrUpdateAccessHolder(values) {
    const { contactInfo, accountNumber, company } = values;
    if (isUndefined(contactInfo)) return;
    if (isEqual(contactInfo.addresses, defaultValues.contactInfo.addresses)) {
      contactInfo.addresses = undefined;
    }
    if (isEqual(contactInfo.emails, defaultValues.contactInfo.emails)) {
      contactInfo.emails = undefined;
    }
    if (
      isEqual(contactInfo.phoneNumbers, defaultValues.contactInfo.phoneNumbers)
    ) {
      contactInfo.phoneNumbers = undefined;
    }
    contactInfo.firstName = contactInfo.firstName;
    contactInfo.lastName = contactInfo.lastName;
    contactInfo.entityID = facilityGroupID || facilityID;
    accessHolder.contactInfo = contactInfo;
    accessHolder.accountNumber = accountNumber;
    accessHolder.company = company;
    let response;
    if (isUndefined(accessHolder.accessHolderID)) {
      try {
        accessHolder.startDate = new Date();
        response = await contractService.createAccessHolder(accessHolder);
      } catch (err) {
        if (err?.response?.status === 409) {
          enqueue("Failed to create access holder. This account number is already in use.", {
            variant: "error",
            tag: "accessHolderAcctNumberConflict",
          });
          return;
        }
        enqueue("Failed to create access holder", {
          variant: "error",
          tag: "accessHolderCreateError",
        });
        return;
      }
    } else {
      try {
        response = await contractService.updateAccessHolder(accessHolder);
      } catch (err) {
        if (err?.response?.status === 409) {
          enqueue("Failed to update access holder. This account number is already in use.", {
            variant: "error",
            tag: "accessHolderAcctNumberConflict",
          });
          return;
        }
        enqueue("Failed to update access holder", {
          variant: "error",
          tag: "accessHolderUpdateError",
        });
        return;
      }
    }

    enqueue(
      `Successfully ${
        isUndefined(accessHolder.accessHolderID) ? "created" : "updated"
      } access holder`,
      {
        variant: "success",
      }
    );

    setAccessHolder((prev) => ({
      ...response.data,
      contactInfo: prev.contactInfo,
    }));
    onChange(response.data);
  }

  const handleDeleteAccessHolder = async () => {
    const confirmed = await textConfirmation({
      title: "Access Holder Delete",
      message: `To confirm the deletion of this access holder, please enter this access holder's first name in the input box below.`,
      textConfirmation: accessHolder.contactInfo.firstName,
    });
    if (confirmed) {
      try {
        await contractService.deleteAccessHolder(accessHolder.accessHolderID);
      } catch {
        enqueue("Failed to delete access holder", {
          variant: "error",
          tag: "accessHolderDeleteError",
        });
        return;
      }
      enqueue("Access Holder deleted successfully", {
        variant: "success",
      });
      setAccessHolder({ accountNumber: generateUUID() });
      onDelete();
    }
  };

  const shouldModificationBeDisabled = () => {
    if (isUndefined(accessHolder.accessHolderID)) {
      return !accessHolderAdd;
    } else {
      return !accessHolderEdit;
    }
  };

  const formatTextField = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    setValue(e.target.name, val.trim());
    trigger(e.target.name);
  };

  return (
    <>
      <StyledPanel
        className={clsx("accessholder-panel", className)}
        headerContent={
          <div className={clsx("header", classes.header)}>
            <div className={clsx("header-left", classes.headerLeft)}>
              <Typography className={clsx("access-holder-info")}>
                Access Holder Info
              </Typography>
              {/* <ContractsDropdown
                className={classes.contractDropdown}
                variant="outlined"
              /> TO BE ADDED AFTER PHASE 1A*/}
            </div>
            <div className={clsx("button-header", classes.headerButtons)}>
              {accessHolderDelete && !isUndefined(accessHolder.accessHolderID) && (
                <Button
                  size="small"
                  className={clsx(
                    "delete-accessholder-btn",
                    classes.mobileBtn,
                    classes.deleteBtn
                  )}
                  startIcon={
                    small === true && (
                      <DeleteIcon className={clsx("delete-icon")} />
                    )
                  }
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteAccessHolder}
                >
                  {!small && "Delete Access Holder"}
                </Button>
              )}
            </div>
          </div>
        }
        cardContent={
          <div className={clsx("card-content", classes.contentRoot)}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(
                  async (data) => await createOrUpdateAccessHolder(data)
                )}
                noValidate
              >
                <div className={clsx("card-top", classes.top)}>
                  <Controller
                    name="accountNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={clsx(
                          "accountNumber-field",
                          classes.accountNumberInput
                        )}
                        size="small"
                        label="Account #"
                        variant="outlined"
                        error={!!errors.accountNumber}
                        helperText={
                          errors.accountNumber && errors.accountNumber.message
                        }
                        InputProps={{
                          readOnly: Boolean(shouldModificationBeDisabled()),
                          "aria-readonly": Boolean(
                            shouldModificationBeDisabled()
                          ),
                          disabled: Boolean(shouldModificationBeDisabled()),
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={clsx(
                          "company-field",
                          classes.accountNumberInput
                        )}
                        size="small"
                        label="Company"
                        variant="outlined"
                        onBlur={formatTextField}
                        error={!!errors.company}
                        helperText={errors.company && errors.company.message}
                        InputProps={{
                          readOnly: Boolean(shouldModificationBeDisabled()),
                          "aria-readonly": Boolean(
                            shouldModificationBeDisabled()
                          ),
                          disabled: Boolean(shouldModificationBeDisabled()),
                        }}
                      />
                    )}
                  />
                </div>

                <div
                  className={clsx(
                    "contact-form-container",
                    classes.root,
                    classes.contactForm
                  )}
                >
                  <ContactSubform
                    disabled={shouldModificationBeDisabled()}
                    requiredFields={["firstName"]}
                  />
                  {!shouldModificationBeDisabled() && (
                    <Button
                      className={clsx(
                        "accessholder-submit-btn",
                        classes.submitBtn
                      )}
                      data-testid="accessholder-submit-btn-test"
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={
                        !isDirty || !isEmpty(errors) || !isValid || isSubmitting
                      }
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
        }
      />
    </>
  );
};

AccessHolderPanel.defaultProps = {
  onDelete: () => {},
  onChange: () => {},
};

AccessHolderPanel.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

export default AccessHolderPanel;
