import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  groupFormControl: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {},
  expansionPanel: {
    display: "flex",
  },
  panelSummary: {
    height: "55px",
  },
  groupRow: {
    paddingLeft: "0px",
  },
  groupList: {
    maxHeight: "400px",
    overflow: "auto",
    width: "100%",
  },
  groupAvatar: { marginRight: theme.spacing(2), marginLeft: theme.spacing(2) },
  groupInfo: {},
  groupName: {
    fontWeight: "bold",
  },
  groupEmail: {},
  title: { color: theme.palette.white?.main, fontWeight: "bold" },
}));
