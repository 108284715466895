import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  taxSettingFormControl: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {},
  expansionPanel: {
    display: "flex",
  },
  panelSummary: {
    height: "55px",
  },
  taxSettingRow: {
    paddingLeft: "0px",
  },
  taxSettingList: {
    maxHeight: "400px",
    overflow: "auto",
    width: "100%",
  },
  taxSettingInfo: {},
  taxSettingName: {
    fontWeight: "bold",
  },
}));
