import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { FormControlLabel, Checkbox } from "@material-ui/core";

/* State */
import useCurrentUser from "../../../hooks/useCurrentUser";

/* Style */
import clsx from "clsx";
import { useStyles } from "./AccountUsersCheckboxStyle";

/* Constants */
import { USER_TYPES } from "../../../constants";

export default function AccountUsersCheckbox({ onClick }) {
  const classes = useStyles();
  const user = useCurrentUser();
  const [accountUsersChecked, setAccountUsersChecked] = useState(
    user.UserType == USER_TYPES.ValidationAccount
  );

  useEffect(() => {
    setAccountUsersChecked(user.UserType == USER_TYPES.ValidationAccount);
  }, [user.UserType]);

  const handleClick = () => {
    if (user.UserType == USER_TYPES.ValidationAccount) return;
    setAccountUsersChecked(!accountUsersChecked);
    onClick();
  };

  return (
    <FormControlLabel
      className={clsx("account-users-checkbox", classes.accountUsersCheckbox)}
      control={
        <Checkbox
          aria-label="account users switch checkbox"
          checked={accountUsersChecked}
          onClick={handleClick}
        />
      }
      label="Account Users"
    />
  );
}

AccountUsersCheckbox.defaultProps = {
  onClick: () => {},
};

AccountUsersCheckbox.propTypes = {
  onClick: PropTypes.func,
};
